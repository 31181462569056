import Collapse from 'bootstrap/js/dist/collapse'
import Dropdown from 'bootstrap/js/src/dropdown'
import 'lazysizes';
// import a plugin
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

prestashop.responsive = prestashop.responsive || {};
prestashop.responsive.current_width = window.innerWidth;
prestashop.responsive.min_width = 768;
prestashop.responsive.mobile = prestashop.responsive.current_width < prestashop.responsive.min_width;
if (!prestashop.responsive.mobile) {
    // window.addEventListener('mouseup', (e) => {
    //     const toClose = document.querySelectorAll('.dropdown-menu');
    //     for (const item of toClose) {
    //         if (!item.contains(e.target)) {
    //             item.style.display = 'none';
    //         }
    //     }
    // });
    // let expand = document.querySelectorAll('.dropdown');
    // for (const item of expand) {
    //     item.addEventListener('click', () => {
    //         const current = item.querySelector('.dropdown-menu');
    //         if (!current.style.display || current.style.display === 'none') {
    //             current.style.display = 'block';
    //         }
    //         else {
    //             current.style.display = 'none';
    //         }
    //     });
    // }
}
const scrollTop = document.getElementById('scroll_top');
scrollTop.addEventListener('click', () => window.scrollTo({
    top: 0, behavior: 'smooth'
}));
window.addEventListener('scroll', () => {
    const body = document.body.getBoundingClientRect();
    if (body.top < -100) {
        scrollTop.style.display = 'block';
    }
    else {
        scrollTop.style.display = 'none';
    }
    stickyFilter();
});
window.addEventListener("load", (e) => {
    // lazy();
    // const elements = ['header', 'main', 'footer'];
    // for (const element of elements) {
    //     items = document.querySelectorAll(element);
    //     for (const item of items) {
    //         item.style.opacity = 1;
    //     }
    // }
    var collapseElementList = [].slice.call(document.querySelectorAll('.collapse'))
    var collapseList = collapseElementList.map(function (collapseEl) {
        return new Collapse(collapseEl, {
            toggle: false
        })
    });
    var dropdownElementList = [].slice.call(document.querySelectorAll('.dropdown-toggle'))
    var dropdownList = dropdownElementList.map(function (dropdownToggleEl) {
        return new Dropdown(dropdownToggleEl)
    })
    // window.af_custom_updateProductListDOM = 1;
    if (typeof customThemeActions !== 'undefined') {
        customThemeActions.updateContentAfter = function (r) {
            prestashop.emit('afterUpdateProductList', {});
        };
        stickyFilter();
    }
});
prestashop.on('updatedProduct', function () {
    // lazy();
    // btnFilter();
});
prestashop.on('afterUpdateProductList', function () {
    // lazy();
    btnFilter();
});
prestashop.on('updateCart', function () {
    // lazy();
});
prestashop.on('changedCheckoutStep', function () {
    console.log('changedCheckoutStep');
    // lazy();
});
let btnFilter = function () {
    let btnFilter = document.querySelector('.btn-filter');
    if(null!==btnFilter) {
        btnFilter.onclick = function () {
            let body = document.querySelector('body');
            body.classList.add('show-filter');
        }
    }
}
let lazy = function () {
    [].forEach.call(document.querySelectorAll('img[data-src]'), (img) => {
        img.setAttribute('src', img.getAttribute('data-src'));
        img.onload = function () {
            img.removeAttribute('data-src');
        };
    });
};
$(function () {
    $(document).on('click', 'button[data-action="show-password"]', function () {
        var t = $(this);
        let html = t.attr('data-text-hide');
        t.attr('data-action', 'hide-password').html(html).parent().parent().find('input[name="password"]').attr('type', 'text');
    }).on('click', 'button[data-action="hide-password"]', function () {
        var t = $(this);
        let html = t.attr('data-text-show');
        t.attr('data-action', 'show-password').html(html).parent().parent().find('input[name="password"]').attr('type', 'password');
    }).on('click', '.top-mobile-search', function () {
        $('.search-widget').show();
    }).on('click', '.search-widget-close', function () {
        $('.search-widget').hide();
    }).on('click', '.top-mobile-menu', function () {
        $('#mobile_top_menu_wrapper').show();
    });
    btnFilter();
});

function stickyFilter() {
    let body = document.querySelector('body#category');
    if (null !== body) {
        if (window.scrollY >= 75.5) {
            body.classList.add('fixed-menu');
        }
        else {
            body.classList.remove('fixed-menu');
        }
    }
}

function toggleMobileStyles() {
    if (prestashop.responsive.mobile) {
        $("*[id^='_desktop_']").each((idx, el) => {
            const target = $(`#${el.id.replace('_desktop_', '_mobile_')}`);
            if (target.length) {
                swapChildren($(el), target);
            }
        });
    }
    else {
        $("*[id^='_mobile_']").each((idx, el) => {
            const target = $(`#${el.id.replace('_mobile_', '_desktop_')}`);
            if (target.length) {
                swapChildren($(el), target);
            }
        });
    }
    prestashop.emit('responsive update', {
        mobile: prestashop.responsive.mobile
    });
}

$(window).on('resize', () => {
    const cw = prestashop.responsive.current_width;
    const mw = prestashop.responsive.min_width;
    const w = window.innerWidth;
    const toggle = (cw >= mw && w < mw) || (cw < mw && w >= mw);
    prestashop.responsive.current_width = w;
    prestashop.responsive.mobile = prestashop.responsive.current_width < prestashop.responsive.min_width;
    if (toggle) {
        toggleMobileStyles();
    }
});

function swapChildren(obj1, obj2) {
    const temp = obj2.children().detach();
    obj2.empty().append(obj1.children().detach());
    obj1.append(temp);
}

$(document).ready(() => {
    if (prestashop.responsive.mobile) {
        toggleMobileStyles();
    }
});
